<template>
  <router-view />
</template>
<script setup>
import { onMounted, ref } from "vue";
import { apiGet } from "./plugins/axiosInstance";

const allSubList = ref("");
const getSubjectsList = () => {
  apiGet("course/QueryAllCourse/").then(res => {
    allSubList.value = res.data;
  });
};

const allProList = ref("");
const getAllProList = () => {
  apiGet("query/QueryCity").then(res => {
    allProList.value = res.data;
  });
};
const setDict = async () => {
  await getSubjectsList();
  await getAllProList();
  // localStorage.setItem('allProList', allProList.value)
  Object.defineProperty(window, "$ysl_dict", {
    configurable: false,
    enumerable: false,
    get() {
      return {
        allSubList: allSubList.value,
        allProList: allProList.value
      };
    }
  });
};
onMounted(() => {
  setDict();
});
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
ul {
  list-style: none;
}
.demo-form-inline {
  .el-input,
  .el-select {
    width: 230px !important;
  }
  .el-date-editor {
    width: 210px !important;
  }
}
// .el-input-text {
//   width: 300px !important;
// }
.sub-text-title {
  margin-bottom: 10px;
  font-weight: 700;
  padding-left: 20px;
}
.button-span {
  font-size: 15px;
}
.footer-button {
  margin-left: 60px;

  .el-button {
    width: 80px;
  }
}
.green-text {
  color: rgb(28, 152, 28);
}
.cursor-text {
  cursor: pointer;
  font-weight: bold;
}
.cursor-drop {
  cursor: no-drop;
}
.red-text {
  color: red;
}
.redbgc-text {
  color: #fff;
  background: red;
  padding: 12px 8px;
}
.orange-text {
  color: orange;
}
.bold-text {
  font-weight: bold !important;
}
</style>

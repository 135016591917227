// const account_type = sessionStorage.getItem('account_type')

export let shortcuts = [
  {
    text: '最近7天',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
      return [start, end]
    },
  },
  {
    text: '最近30天',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      return [start, end]
    },
  },
  {
    text: '当日',
    value: () => {
      let date = new Date();
      let day = date.getDate();
      date.setDate(day);
      date.setHours(0, 0, 0, 0)
      // let endDate = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1);
      return [date.getTime(), date.getTime()]
    },
  },
  {
    text: '当月',
    value: () => {
      let date = new Date();
      date.setDate(1);
      date.setHours(0, 0, 0, 0)
      let endDate = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1);
      return [date.getTime(), endDate]
    },
  },
];

// 获取当前日期
const dates = new Date();
const year = dates.getFullYear();
const month = dates.getMonth();
const day = dates.getDate();
const hours = dates.getHours();
const minutes = dates.getMinutes();
const setZeroDate = (date) => {
  if (date < 9) {
    return '0' + date
  } else {
    return date;
  }
}
// 当前时间
export const currentTime = `${year}-${month < 9 ? 0 : ""}${month + 1}-${setZeroDate(day)} ${setZeroDate(hours)}:${setZeroDate(minutes)}`;

// 最近30天
export const oldThreeMonthTime = `${year}-${month < 9 ? 0 : ""}${month}-${day < 10 ? 0 : ""}${day + 1}`;

const getDateTime = (date) => {
  const newDate = new Date(date.getTime());
  console.log(newDate, 'newDate')
  const year = newDate.getFullYear();
  const month = ('0' + (newDate.getMonth() + 1)).slice(-2);
  const day = ('0' + newDate.getDate()).slice(-2);
  return `${year}-${month}-${day}`
}
// 当月
export const monthTime = () => {
  let date = new Date();
  date.setDate(1);
  date.setHours(0, 0, 0, 0)
  let endDate = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1);
  return [getDateTime(date), getDateTime(endDate)]
}

export const disabledDateFn = (time) => {
  if (sessionStorage.getItem('account_type') == 1 || sessionStorage.getItem('account_type') == 6) {
    return false
  } else {
    return time.getTime() > Date.now() || time.getTime() < Date.now() - 32 * 24 * 3600 * 1000;
  }
}
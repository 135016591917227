import { createRouter, createWebHistory } from 'vue-router'
import { routes } from './routes'


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
// import { userStore } from '@/store/index';
router.beforeEach((to, from, next) => {
  let token = sessionStorage.getItem('token');
  if (token != '' && token != null) {
    next();
  } else {
    if (to.path === '/login') {
      next();
    } else {
      next('/login');
    }
  }
  // const store = userStore();
  // console.log(store ,'store')
  // //判断是否有权限返回登录界面
  // if (from.meta.isAuth) {
  //     if (store.userInfo.token) {
  //         next()
  //     } else {
  //         next("/login")
  //     }
  // } else {
  //     console.log("没有权限");
  //     next()
  // }
})
export default router

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
const app = createApp(App)

// axios 接入
import axios from '@/plugins/axiosInstance.js'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
app.use(ElementPlus, {
    locale: zhCn,
})
// element ICON 注册
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

// 全局组件注册
import useLayout from '@/components/useLayout.vue'
app.component('useLayout', useLayout);
app.use(store).use(router).mount('#app')

app.config.globalProperties.$axios=axios;